#whatsappLogo {
  position: fixed;
  bottom: 30px;
  right: -100%;
  transition: 0.6s;
  width: 100%;
  height: 80px;
  width: 80px;
  z-index: 300;
  cursor: pointer;
}
#whatsappLogo:hover {
  transform: scale(1.05);
}
#whatsappLogo.show {
  right: 50px;
}
@media (max-width: 1000px) {
  #whatsappLogo {
    height: 50px;
    width: 50px;
  }
}
@media (max-width: 710px) {
  #whatsappLogo.show {
    right: 20px;
  }
  #whatsappLogo {
    height: 40px;
    width: 40px;
  }
}
